<template>
  <div :active.sync="isActive">
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(sms, index) in entitySummaries"
        :key="sms.id"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(sms, index, $event)">
        <td @dblclick="editMessage(sms, index)">{{ sms.message }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-danger is-small is-inverted"
            @click="deleteMessage(sms.id, index, sms.message)"
            data-tooltip="Click to delete">
            <span class="icon is-medium">
              <i class="mdi mdi-delete mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>

    <div class="field"
      v-if="isEnableSave">
      <label class="label">Message :</label>
      <div class="control">
        <textarea class="textarea"
          :class="{ 'is-danger' : !isMessageEntered}"
          id="smsMessage"
          v-focus-inserted
          v-model="smsMessage"
          placeholder="Enter New Message Here..."
          @input="enterMessage"
          maxlength="2000" />
        <div class="is-flex is-justify-content-space-between">
          <span class="help">{{ smsMessage.length }}/2000</span>
          <span class="help is-danger"
            v-if="!isMessageEntered && isEnableSave">Message is required.</span>
        </div>
      </div>

      <label class="label">Insert : </label>
      <div>
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[NAME]')"
          data-tooltip="Add Name">
          <span>Name</span>
        </button>&nbsp;
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[REGO]')"
          data-tooltip="Add Rego">
          <span>Rego</span>
        </button>&nbsp;
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[EXCESS]')"
          data-tooltip="Add Excess">
          <span>Excess</span>
        </button>&nbsp;
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[EST START DATE]')"
          data-tooltip="Add Estimated Start Date">
          <span>Estimated Start Date</span>
        </button>&nbsp;
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[EST END DATE]')"
          data-tooltip="Add Estimated End Date">
          <span>Estimated End Date</span>
        </button>&nbsp;
        <button class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[SHOPNAME]')"
          data-tooltip="Add Shop Name">
          <span>Shop Name</span>
        </button>&nbsp;
        <button
          class="button is-small is-rounded is-outlined is-info tooltip"
          @click="insertTag('[IMAGEUPLOAD]')"
          data-tooltip="Attach Image Hyperlink">
          <span>Image Upload Link</span>
        </button>
      </div>
    </div>

    <unsaved-modal v-if="entity"
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'"
      v-model="entity">
      <p slot="text-title">Delete Message Template</p>
      <p slot="text-content">
        Message Template
        <span class="has-text-primary has-text-weight-bold">{{ `${entity.message}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { UnsavedModal, ConfirmModal } from '@/components/BulmaModal'
import { Columns } from './columns'
import { FocusInserted } from '@/components/directives'
import EventBus from '@/components/EventBus'
import SmsService from './SmsService'

export default {
  name: 'SmsMessageTemplates',
  components: {
    BulmaTable,
    UnsavedModal,
    ConfirmModal
  },

  directives: {
    FocusInserted
  },
  props: {
    value: null,
    enableSave: false,
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false,
      isNew: false,
      isEdit: false,
      isDeleted: false,
      isBack: false,
      isClose: false,
      isEnableSave: false,
      isMessageEntered: true,
      isUnsavedModalActive: false,
      isConfirmModalActive: false,
      isEmpty: false,
      totalRows: 0,
      entitySummaries: {},
      entity: { id: '', message: '' },
      selected: { id: '', message: '' },
      filter: {
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 5
      },
      oriMsg: '',
      selectedRow: null,
      isTableLoading: false,
      smsMessage: ''
    }
  },
  computed: {
    columns() {
      return Columns
    }
  },
  created() {
    this.smsMessage = ''
    this.getData()
    this.isEnableSave = this.enableSave
  },
  methods: {
    async getData() {
      this.isTableLoading = true
      this.entitySummaries = await SmsService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    enterMessage() {
      this.isMessageEntered = this.smsMessage !== ''
      if (this.isMessageEntered) {
        this.isEnableSave = true
      }
    },
    reset() {
      this.isNew = false
      this.isDeleted = false
      this.isEdit = false
      this.isUnsavedModalActive = false
      this.isConfirmModalActive = false
      this.entity.id = ''
      this.entity.message = ''
      this.oriMsg = this.smsMessage = ''
      this.isEnableSave = false
      this.$emit('save-button', false)
    },
    add() {
      this.reset()
      this.selectedRow = ''
      this.isMessageEntered = false
      this.isEnableSave = true
      this.isNew = true
    },
    back() {
      if (this.oriMsg !== this.smsMessage) {
        this.isBack = true
        this.isUnsavedModalActive = true
      } else {
        this.$emit('back')
      }
    },
    close() {
      if (this.oriMsg !== this.smsMessage) {
        this.isClose = true
        this.isUnsavedModalActive = true
      } else {
        this.$emit('close', true)
      }
    },
    editMessage(entity, index) {
      // add new -> edit message
      if (this.oriMsg !== this.smsMessage) {
        this.isEdit = true
        this.isUnsavedModalActive = true
        // edit message
      } else {
        this.isNew = false
        this.isEdit = true
        this.isEnableSave = true
        this.entity.id = entity.id
        this.entity.message = entity.message
        this.oriMsg = entity.message
        this.smsMessage = entity.message
        this.smsMessage.FocusInserted
        this.isMessageEntered = this.smsMessage !== ''
        this.isEnableSave = true
        this.$emit('save-button', true)
      }
    },
    deleteMessage(id, index, msg) {
      this.isDeleted = true
      if (!this.isEdit) {
        this.entity.id = id
      }
      if (!this.isEdit && this.oriMsg !== this.smsMessage && this.entity.id !== this.selected.id) {
        this.entity.id = this.selected.id
        this.entity.message = this.selected.message
        this.isUnsavedModalActive = true
      } else if (this.smsMessage != msg && this.smsMessage != '' && this.isNew) {
        this.isUnsavedModalActive = true
        // } else if (this.isEdit && this.selected.id !== id) {
        // edit msg then delete on same msg or other msg
      } else if (this.isEdit && this.isDeleted && this.oriMsg !== this.smsMessage && this.entity.id !== id) {
        this.isUnsavedModalActive = true
      } else {
        this.entity.id = id
        this.entity.message = msg
        this.isNew = false // set to false when add new message is empty and then click on delete
        this.isEdit = false // set to false when no change on edit message and then click on delete
        this.isConfirmModalActive = true
      }
    },
    async deleteEntity(confirmDelete) {
      if (confirmDelete) {
        try {
          await SmsService.deleteEntity(this.selected.id)
          this.getData()
          this.isEnableSave = false
          this.isMessageEntered = true
          // for edit then edit same message and
          // for add new then highlight and delete other message
          if (
            (!this.isNew && !this.isEdit && this.isDeleted) ||
            (!this.isNew && !this.isEdit && this.entity.id == this.selected.id) ||
            (!this.isNew && this.isEdit && this.entity.id == this.selected.id)
          ) {
            this.reset()
          }
          this.selectedRow = ''
          this.$emit('save-button', false)
        } catch (e) {
          EventBus.$emit('serviceError', e.response.status)
          this.$notification.openMessageXhrError('SMS Message', e)
          return false
        }
      } else {
        if (!this.isNew && this.isEdit && this.entity.id == this.selected.id) {
          this.isConfirmModalActive = false
          this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.message === this.oriMsg))
        } else {
          this.reset()
          this.$emit('save-button', false)
        }
      }
    },
    cancel() {
      this.reset()
      this.selectedRow = ''
    },
    async saveSms() {
      if (this.isNew) {
        await this.save(true)
      } else if (this.isEdit) {
        await this.save(false)
      }
    },
    async save(isNew) {
      var newMsg = this.smsMessage
      this.isEmpty = false
      if (newMsg == '') {
        this.isEmpty = true
        this.$notification.openNotificationWithType('danger', 'SMS Message', 'Validation errors. Please fix before saving')
        return false
      }
      if (this.oriMsg == newMsg && this.entity.id == this.selected.id) {
        this.$notification.openNotificationWithType('warning', 'SMS Message', 'No changes detected. Not saved')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        // add new message
        if (isNew) {
          this.entity.id = 0
          this.entity.message = newMsg
          await SmsService.postEntity(this.entity)
          await this.getData() // wait to get latest order after add
          this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.message === newMsg)) // get latest index for case NOT from save continue
          // edit message
        } else {
          this.entity.message = newMsg
          await SmsService.putEntity(this.entity)
          await this.getData() // wait to get latest order after edit
          this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.id === this.selected.id)) // get latest index for case NOT from save continue
        }

        this.reset()
        this.$notification.success('SMS Message', 'Save successful')
        this.$emit('save-button', false)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        this.$notification.openMessageXhrError('SMS Message', e)
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    async saveContinue() {
      if (this.isNew && this.isDeleted) {
        await this.deleteEntity(true)
        await this.save(true)
        this.reset()
      } else if (this.isEdit && this.isDeleted) {
        await this.deleteEntity(true)
        await this.save(false)
        this.isUnsavedModalActive = false
        this.isEnableSave = false
      } else if (this.isNew && this.isEdit) {
        await this.save(true)
        this.isEdit = true // isEdit has been set to false after save. Re-set to true for edit on selected msg
        this.oriMsg = this.selected.message
        this.entity.id = this.selected.id
        this.entity.message = this.selected.message
        this.smsMessage = this.selected.message
        this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.id === this.selected.id)) // for case add new then edit, get latest index after insert
        this.isUnsavedModalActive = false
        this.isEnableSave = true
        this.$emit('save-button', true)
      } else if (!this.isNew && this.isEdit) {
        // edit not save, then edit on other message
        await this.save(false)
        this.isEdit = true // isEdit has been set to false after save. Re-set to true for edit on selected msg
        this.oriMsg = this.selected.message
        this.entity.id = this.selected.id
        this.entity.message = this.selected.message
        if (this.isEmpty) {
          this.smsMessage = ''
        } else {
          this.smsMessage = this.selected.message
        }
        this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.id === this.selected.id)) // for edit then edit others, get latest index after insert
        this.isUnsavedModalActive = false
        this.isEnableSave = true
        this.$emit('save-button', true)
      } else if (this.isNew && !this.isEdit && !this.Deleted) {
        //back button, add new but not save, then click save continue
        await this.save(true)
      }

      // isEmpty is to check if msg empty then it not allow back to SmsSend
      if (this.isBack && !this.isEmpty) {
        this.$emit('back')
      }

      if (this.isClose && !this.isEmpty) {
        this.$emit('close', true)
      }
    },
    skipSave() {
      if (this.isBack) {
        this.$emit('back')
      } else if (this.isClose) {
        this.$emit('close', true)
      } else if (this.isDeleted) {
        this.deleteEntity(true)
        this.reset()
      } else if (this.isEdit) {
        this.isUnsavedModalActive = false
        this.smsMessage = this.selected.message
        this.smsMessage.FocusInserted
        this.isNew = false
        this.isEdit = true
        this.oriMsg = this.smsMessage
        this.entity.id = this.selected.id
        this.entity.message = this.selected.message
      } else if (this.isNew) {
        this.isUnsavedModalActive = false
        this.smsMessage = this.selected.message
        this.smsMessage.FocusInserted
        this.isNew = false
        this.isEdit = true
        this.oriMsg = this.smsMessage
      }
    },
    closeModal() {
      this.isDeleted = false
      this.isBack = false
      this.isClose = false
      this.isUnsavedModalActive = false
      if (this.isEnableSave) {
        this.smsMessage.FocusInserted
        this.selectedRow = this.entitySummaries.indexOf(this.entitySummaries.find((i) => i.id === this.entity.id))
      }
    },
    highlightSelected(entity, index, event) {
      this.selectedRow = index
      this.selected = entity
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.getData()
    },
    insertTag(myValue) {
      var myField = document.getElementById('smsMessage')

      if (document.selection) {
        myField.focus()
        var sel = document.selection.createRange()
        sel.text = myValue
      } else if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart
        var endPos = myField.selectionEnd
        myField.value = myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length)
      } else {
        myField.value += myValue
      }
      this.smsMessage = myField.value
      this.enterMessage()
      myField.focus()
    }
  }
}
</script>
