<template>
  <div v-if="value && customer && vehicle"
    class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half"
      v-show="!editMaximize">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Communications</p>
          <div class="tabs is-boxed">
            <ul>
              <li :class="activeTab == 'SMS' ? 'is-active' : ''"
                @click="setActiveTab('SMS')">
                <a>
                  <span class="icon is-small">
                    <i class="mdi mdi-message-text mdi-20px"
                      aria-hidden="true" />
                  </span>
                  <span class="has-badge-child-offset3">SMS</span>
                  <span v-show="smsUnreadCount"
                    class="has-badge-rounded has-badge-medium has-badge-info"
                    :data-badge="smsUnreadCount" />
                </a>
              </li>
              <li v-if="isShowWhatsApp"
                :class="activeTab == 'WhatsApp' ? 'is-active' : ''"
                @click="setActiveTab('WhatsApp')">
                <a>
                  <span class="icon is-small">
                    <i class="mdi mdi-whatsapp mdi-20px icon-green"
                      aria-hidden="true" />
                  </span>
                  <span class="has-badge-child-offset3">WhatsApp</span>
                  <span v-show="whatsappUnreadCount"
                    class="has-badge-rounded has-badge-medium has-badge-info"
                    :data-badge="whatsappUnreadCount" />
                </a>
              </li>
              <li :class="activeTab == 'Email' ? 'is-active' : ''"
                @click="setActiveTab('Email')">
                <a>
                  <span class="icon is-small">
                    <i class="mdi mdi-email mdi-20px"
                      aria-hidden="true" />
                  </span>
                  <span class="has-badge-child-offset3">Email</span>
                  <span v-show="emailUnreadCount"
                    class="has-badge-rounded has-badge-medium has-badge-info"
                    :data-badge="emailUnreadCount" />
                </a>
              </li>
            </ul>
          </div>
          <div ref="messageContainer"
            class="container">
            <div v-for="(message) in messages"
              :key="message.id"
              class="is-flex px-1 py-2"
              :class="{ 'is-justify-content-flex-end': message.isIncoming }">

              <transition mode="out-in"
                :enter-active-class="`animate__animated animate__slideInRight`"
                :leave-active-class="`animate__animated animate__slideInRight`">
                <article class="message is-flex"
                  style="flex-direction: column"
                  :class="[{'message-reply': message.isIncoming}, {'unread-animation': !message.isRead}]">
                  <div class="is-flex"
                    style="justify-content: space-between">
                    <span v-if="message.isIncoming"
                      class="is-size-7 help has-text-weight-medium">{{ truncate(message.sender, 50) }}</span>
                    <span v-if="message.isOutgoing && message.recipients"
                      class="is-size-7 help has-text-weight-medium">{{ truncate(message.recipients, 50) }}</span>
                    <div>
                      <span class="is-size-7 help is-italic has-text-grey-light createdDate is-inline-block mr-3">
                        {{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}
                      </span>
                      <span v-if="isEmail && message.isOutgoing"
                        class="icon is-small tooltip is-tooltip-left is-clickable mr-2"
                        :data-tooltip="getEmailStatus(message)"
                        @click="openMessageModal(message)">
                        <i class="mdi mdi-18px"
                          :class="getEmailStatusIndicator(message)" />
                      </span>
                      <span v-if="isEmail"
                        class="icon is-small tooltip is-tooltip-left is-clickable mr-2"
                        data-tooltip="View Email"
                        @click="viewEmail(message)">
                        <i class="mdi mdi-18px mdi-email-open" />
                      </span>
                      <span v-if="isEmail"
                        v-show="message.attachments"
                        class="icon is-small tooltip is-tooltip-left is-clickable mr-2"
                        data-tooltip="Attachment"
                        @click="openAttachmentModel(message.id, message.isIncoming)">
                        <i class="mdi mdi-18px mdi-attachment" />
                      </span>
                      <span v-if="isEmail"
                        class="icon is-small tooltip is-tooltip-left is-clickable"
                        data-tooltip="Reply"
                        @click="replyTo(message)">
                        <i class="mdi mdi-18px"
                          :class="[{'mdi-reply-outline': !isSelectedReplyToMessage(message.messageId)}, {'mdi-reply': isSelectedReplyToMessage(message.messageId)}]" />
                      </span>
                    </div>
                  </div>

                  <!-- <div :class="[{'speech-left': message.isOutgoing}, {'speech-right': message.isIncoming}]"> -->
                  <div class="message-body py-1 px-2"
                    :class="messageBodyStyle(message)">
                    <div :v-show="activeTab == 'Email'"
                      class="has-text-weight-semibold">
                      {{ message.subject }}
                    </div>
                    <span class="message-sent">{{ truncate(message.message, 100) }}</span>
                    <div class="is-flex help is-italic"
                      :class="{ 'is-justify-content-flex-end': message.isIncoming }" />
                  </div>
                  <!-- </div> -->

                </article>
              </transition>
            </div>
            <quote-email-viewer-modal :active.sync="isViewEmail"
              :show-icon="true"
              :email="viewMessage"
              @cancel="closeViewEmail"
              @close="closeViewEmail">
              <p slot="text-title">Email</p>
            </quote-email-viewer-modal>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-vertical is-half"
      v-if="isEmail">
      <div class="tile is-parent">
        <quote-email v-if="isEmail && isEmailerActive"
          :quote="entity"
          :enable-send="true"
          :enable-attach="true"
          :reply-to-message-id="replyToMessageId"
          :reply-to-email="replyToEmail"
          :selected-attachment-email-id="selectedAttachmentEmailId"
          :is-incoming="isIncoming"
          :msg-details="msgDetails"

          @sent="emailSent"
          @resetEmail="resetEmail"
          @close="closeAttachmentModel"
          @toggleEditMaximize="toggleEditMaximize" />

      </div>
    </div>
    <div class="tile is-vertical is-half"
      v-else>
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="field">
            <label for=""
              class="label">Send To</label>
            <div class="control">
              <the-mask v-model="msgDetails.mobile"
                :mask="phoneNoMask"
                :tokens="phoneNoTokens"
                class="input"
                placeholder="Mobile No."
                maxlength="20"
                v-focus-inserted />
            </div>
          </div>
          <div class="field">
            <label class="label">Message</label>
            <div class="control is-expanded">
              <multiselect v-model="selectedTemplate"
                track-by="message"
                label="message"
                placeholder="Select Template"
                :options="templates"
                :loading="isTemplateLoading"
                :searchable="true"
                :allow-empty="true"
                :options-limit="templateFilter.pageSize"
                @select="selectTemplate" />
            </div>
          </div>
          <div v-if="approvedTemplate"
            class="field">
            <div class="control">
              <span class="is-size-7 help has-text-grey-light">Approved Template</span>
              <div class="box">
                {{ approvedTemplate }}
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <textarea v-model="msgDetails.message"
                class="textarea"
                rows="8"
                :maxlength="maxLength"
                placeholder="Text message" />
            </div>
            <span class="help">{{ msgDetails.message.length }}/{{ maxLength }}</span>
          </div>
          <div class="is-flex is-justify-content-space-between">
            <button class="button is-primary tooltip"
              data-tooltip="Edit message template"
              @click="isSmsTemplateModalActive = true">
              <span class="icon">
                <i class="mdi mdi-pencil mdi-20px" />
              </span>
              <span>Edit Template</span>
            </button>
            <button class="button is-success tooltip"
              @click="send()"
              @sent="emailSent"
              :data-tooltip="getToolTip()"
              :disabled="!msgDetails.message || !msgDetails.mobile">
              <span class="icon">
                <i class="mdi mdi-message-text mdi-20px" />
              </span>
              <span>Send</span>
            </button>
          </div>
        </article>
      </div>
    </div>

    <sms-template-modal v-if="isSmsTemplateModalActive"
      :active.sync="isSmsTemplateModalActive"
      @close="closeSmsTemplateModal" />

    <base-modal-ex :active.sync="isMessageModalActive"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      :min-width="60"
      has-modal-card>
      <header class="modal-card-head">
        <p class="modal-card-title"
          :class="{ 'is-flex-items-center' : true}">
          <span>Email Delivery Failed Message</span>
        </p>
      </header>
      <section class="modal-card-body p-0 m-0"
        style="position:relative;">
        <div class="columns p-0 m-0">
          <div class="column p-0 m-0">
            <div class="box p-0 m-0">
              <div class="columns is-multiline p-0 m-0">
                <div class="column is-full p-3 m-0">
                  <div v-html="messageModalMessage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot">
        <div :style="`width: 100%; display: flex; justify-content: space-between;`">
          <div style="align-self: flex-start;" />
          <div style="align-self: flex-end;">
            <button class="button tooltip"
              @click="closeMessageModal()"
              data-tooltip="Close">
              <span class="icon">
                <i class="mdi mdi-close mdi-18px" />
              </span>
              <span>Close</span>
            </button>
          </div>
        </div>
      </footer>
    </base-modal-ex>
  </div>
</template>

<script>
import { SmsSendMessageModel } from '@/classes/viewmodels'
import Multiselect from 'vue-multiselect'
import { FocusInserted } from '@/components/directives'
import { SmsService, WhatsAppService, EmailService } from '@/services'
import { PhoneTypes, CommunicationTypes, NotificationType } from '@/enums'
import SmsTemplateModal from '@/components/Sms/SmsTemplateModal'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { truncate } from '@/utils/StringFunctions'

// import { VueEmail } from '@/components/VueEmail'
// import mimeTypes from 'mime-types'
// import { Emailer } from '@/classes'
// import StoreUtil from '@/store/utils'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteCommunicationsMixin from './mixins/QuoteCommunicationsMixin'
// import { QuoteImage } from '../components/QuoteImage'
import QuoteEmail from './components/QuoteEmail.vue'
import QuoteEmailViewerModal from './components/QuoteEmailViewerModal.vue'
import { BaseModalEx } from '@/components/BulmaModal'

// Temporary const for copyright message
// const divider = '=============================================================='
// const copyright =
//   'All accompanying materials are the exclusive property of [COMPANY].  All [COMPANY] rights are reserved.  These Materials must not be reproduced, adapted, modified, published, or communicated to any person in any form, without express prior written consent of [COMPANY].  Any unauthorised use of these Materials will be vigorously pursued and may result in legal action, including a claim for damages or an account for loss of profits.'

export default {
  name: 'QuoteCommunications',
  // components: { Multiselect, SmsTemplateModal, VueEmail, QuoteEmail },
  components: { Multiselect, SmsTemplateModal, QuoteEmail, QuoteEmailViewerModal, BaseModalEx },
  directives: {
    FocusInserted
  },
  mixins: [QuoteCommunicationsMixin],
  props: {
    value: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    },
    vehicle: {
      type: Object,
      default: null
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    }
  },
  data: () => {
    return {
      entity: null,
      phoneNoMask: Array(31).join('P'),
      phoneNoTokens: {
        P: {
          pattern: /[0-9 ()\-+]/
        }
      },
      msgDetails: {
        quoteId: null,
        customer: null,
        rego: null,
        jobStart: null,
        jobEnd: null,
        excess: null,
        mobile: null,
        message: '',
        shopName: '',
        imgLink:''
      },
      smsDetails: new SmsSendMessageModel(),
      isTemplateLoading: false,
      templates: [],
      messages: [],
      selectedTemplate: '',
      templateFilter: {
        message: '',
        pageIndex: 1,
        pageSize: 999
      },
      isSmsTemplateModalActive: false,
      interval: null,
      activeTab: CommunicationTypes.SMS,
      maxLength: 0,
      isShowWhatsApp: false,
      approvedTemplate: '',
      emailUnreadCount: 0,
      smsUnreadCount: 0,
      whatsappUnreadCount: 0,
      replyToMessageId: '',
      replyToSender: '',
      replyToContent: '',
      replyToEmail: {},
      selectedAttachmentEmailId: '',
      isIncoming: null,
      editMaximize: false,
      isViewEmail: false,
      viewMessage: {},
      isMessageModalActive: false,
      messageModalMessage: '',
      isEmailerActive : true
      // isEmailModalActive: false,
      // isImageAttachActive: false,
      // emailer: {
      //   reportName: null
      // },
      // reportBytes: null,
      // reportContentType: '',
      // selectedImages: [],
      // quoteIds: []
    }
  },
  computed: {
    communicationTypes: function () {
      return CommunicationTypes
    },
    isSMS: function () {
      return this.activeTab == CommunicationTypes.SMS
    },
    isWhatsApp: function () {
      return this.activeTab == CommunicationTypes.WhatsApp
    },
    isEmail: function () {
      return this.activeTab == CommunicationTypes.Email
    }

    //reportExtension() {
    //  return mimeTypes.extension(this.reportContentType)
    //}
  },
  watch: {
    isEmail(value) {
      //this.isEmailModalActive = value
      if (!value) {
        this.replyToMessageId = ''
      }
    }
  },
  async created() {
    this.getEntity()
    this.setParams()
    await this.getSettings()
    this.getSmsMessageDropdown()
    this.autoRefresh()
    this.getMessages()
    this.getMaxLength()
    await this.getUnreadCount()
    //this.clearSessionStorage()
    this.assignMessageDetails(this.msgDetails)
  },
  mounted() {
    if (this.customer) {
      const mobile = this.customer.phones.find((p) => p.type === PhoneTypes.Mobile.toString())
      const mobileNo = mobile ? mobile.no : ''
      this.msgDetails.mobile = mobileNo
    }
    //this.resetEmailer()
  },
  updated() {
    const unreadCount = this.messages.reduce((acc, cur) => (!cur.isRead ? ++acc : acc), 0)
    if (this.$refs.messageContainer && unreadCount) {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    async getEntity() {
      if (!this.currentSnapshot) {
        await this.getStoreItem(this.$route.params.quoteId)
      }
      this.entity = _cloneDeep(this.currentSnapshot)
    },
    async getMessages() {
      if (this.isSMS) {
        this.messages = await SmsService.getMessages(this.value.quoteId)
      } else if (this.isWhatsApp) {
        this.messages = await WhatsAppService.getMessages(this.value.quoteId)
      } else if (this.isEmail) {
        this.messages = await EmailService.getMessages(this.value.quoteId)
        //console.log(this.messages)
      }
      this.setMessagesAsRead()
    },
    assignMessageDetails(message) {
      const jobStart = new Date(this.value.jobStart).toLocaleDateString(this.$userInfo.locale)
      const jobEnd = new Date(this.value.jobEnd).toLocaleDateString(this.$userInfo.locale)

      message.quoteId = this.value.quoteId
      message.customer = this.customer.firstname ? this.customer.firstname : this.customer.fullname
      message.rego = this.vehicle.rego
      message.jobStart = jobStart
      message.jobEnd = jobEnd
      message.excess = this.value.excessWithGst ? this.value.excess + roundAwayFromZero((this.value.excess * this.value.gstRate) / 100) : this.value.excess
      message.shopName = this.$userInfo.companyName
    },
    async send() {
      this.$showSpinner('Sending...')
      this.assignMessageDetails(this.msgDetails)
      var type = this.activeTab
      try {
        if (this.isSMS) {
          await SmsService.sendSms(this.msgDetails)
        } else if (this.isWhatsApp) {
          await WhatsAppService.sendWhatsApp(this.msgDetails)
        }
        this.$notification.openNotificationWithType('success', `Send ${type}`, 'Message Sent')
        //if (this.isSMS) this.smsDetails.message = ''
        this.msgDetails.message = ''
        this.selectedTemplate = ''
      } catch (e) {
        this.$notification.openMessageXhrError(`Send ${type}`, e)
      } finally {
        await this.getMessages()
        this.$hideSpinner()
      }
    },
    async getSmsMessageDropdown() {
      this.isTemplateLoading = true
      this.templates = await SmsService.getSmsMessageDropdownProto(this.templateFilter)
      if (this.templateFilter.message !== '') {
        this.selectedTemplate = this.templates.find((c) => c.message === this.templateFilter.message)
      }
      this.isTemplateLoading = false
    },
    selectTemplate(selected) {
      this.templateFilter.message = selected.message
      this.msgDetails.message = selected.message //this.smsDetails.message = selected.message
    },
    async closeSmsTemplateModal() {
      this.isSmsTemplateModalActive = false
      await this.getSmsMessageDropdown()
    },
    autoRefresh() {
      this.interval = setInterval(async () => {
        await this.getMessages()
        await this.getUnreadCount()
      }, 30000)
    },
    stopAutoRefresh() {
      clearInterval(this.interval)
    },
    setMessagesAsRead() {
      const unread = this.messages.filter((m) => !m.isRead)
      if (unread && unread.length > 0) {
        setTimeout(async () => {
          if (this.isSMS) {
            await SmsService.setMessagesAsRead(this.value.quoteId)
          } else if (this.isWhatsApp) {
            await WhatsAppService.setMessagesAsRead(this.value.quoteId)
          } else if (this.isEmail) {
            await EmailService.setMessagesAsRead(this.value.quoteId)
          }
          this.messages.forEach((m) => (m.isRead = true))
        }, 10000)
      }
    },
    async setActiveTab(tab) {
      this.activeTab = tab
      await this.getMessages()
      this.getMaxLength()
      this.resetMsgLength()
    },
    getToolTip() {
      return `Send ${this.activeTab}`
    },
    getMaxLength() {
      this.maxLength =
        this.activeTab == CommunicationTypes.SMS
          ? 2000
          : this.activeTab == CommunicationTypes.WhatsApp
          ? 2000
          : this.activeTab == CommunicationTypes.Email
          ? 2000
          : 0
    },
    resetMsgLength() {
      this.msgDetails.message = this.msgDetails.message.slice(0, this.maxLength)
    },
    setParams() {
      var type = this.$route.params.type
      if (type) {
        if (type == NotificationType.QuoteSmsIncoming) this.activeTab = CommunicationTypes.SMS
        else if (type == NotificationType.QuoteWhatsAppIncoming) this.activeTab = CommunicationTypes.WhatsApp
        else if (type == NotificationType.QuoteEmailIncoming) this.activeTab = CommunicationTypes.Email
      }
    },
    async getSettings() {
      var setting = await WhatsAppService.getCompanyExternalSetting()
      if (setting) {
        this.isShowWhatsApp = setting.active == '1'
        this.approvedTemplate = setting.approvedTemplate
      }
    },
    async getUnreadCount() {
      const emailUnreadCount = await EmailService.getUnreadCount(this.value.quoteId)
      const smsUnreadCount = await SmsService.getUnreadCount(this.value.quoteId)
      const waUnreadCount = await WhatsAppService.getUnreadCount(this.value.quoteId)
      this.emailUnreadCount = emailUnreadCount
      this.smsUnreadCount = smsUnreadCount
      this.whatsappUnreadCount = waUnreadCount
    },
    truncate(str, max) {
      return this.isEmail ? truncate(str, max) : str
    },
    replyTo(message) {
      // .messageId, message.isIncoming ? message.sender : message.recipients, message.message
      const isCancelReply = this.replyToMessageId === message.messageId
      this.replyToMessageId = isCancelReply ? '' : message.messageId
      // this.replyToSender = isCancelReply ? '' : message.isIncoming ? message.sender : message.recipients
      this.replyToEmail = isCancelReply ? {} : message
      // this.replyToContent = isCancelReply ? '' : message
    },
    sent() {

    },
    isSelectedReplyToMessage(messageId) {
      if (!this.replyToMessageId) return false
      return this.replyToMessageId === messageId
    },
    messageBodyStyle(message) {
      if (this.isSelectedReplyToMessage(message.messageId)) return 'is-warning'
      else if (message.isOutgoing) return 'is-info'
      else if (message.isIncoming) return 'is-success'
      else return ''
    },
    openAttachmentModel(messageId, isIncoming) {
      this.selectedAttachmentEmailId = messageId
      this.isIncoming = isIncoming
    },
    closeAttachmentModel() {
      this.selectedAttachmentEmailId = ''
      this.isIncoming = null
    },
    toggleEditMaximize() {
      this.editMaximize = !this.editMaximize
    },
    updateAttachment() {
      this.$emit('image-uploaded')
    },
    viewEmail(message) {
      this.viewMessage = message
      this.isViewEmail = !this.isViewEmail
      //console.log(this.isViewEmail)
    },
    closeViewEmail() {
      this.viewMessage = {}
      this.isViewEmail = !this.isViewEmail
      //console.log(this.isViewEmail)
    },
    getEmailStatusIndicator(message) {
      if (message.isDelivered) return 'mdi-check-all'
      else if (message.isFailed) return 'mdi-check-all has-text-danger'
      else return 'mdi-check'
    },
    getEmailStatus(message) {
      if (message.isDelivered) return 'Delivered at ' + this.$filters.formatDateTimeLocale(message.deliveredAt, this.$userInfo.locale)
      else if (message.isFailed) return 'Failed at ' + this.$filters.formatDateTimeLocale(message.failedAt, this.$userInfo.locale)
      else return 'Sent at ' + this.$filters.formatDateTimeLocale(message.sentAt, this.$userInfo.locale)
    },
    openMessageModal(message) {
      if (!message.isFailed) return
      this.messageModalMessage = message.failedMessage.replace(/\\n/g, '<br/>')
      this.isMessageModalActive = true
    },
    closeMessageModal() {
      this.messageModalMessage = ''
      this.isMessageModalActive = false
    },
    resetEmail(){
      this.replyToMessageId = ''
      // this.replyToSender = ''
      this.replyToContent = ''
      this.replyToEmail = {}
      this.refreshEmailer = true

      if (this.isEmailerActive === true) {
        this.isEmailerActive = false
        this.$nextTick(() => {
          this.isEmailerActive = true
        })
      }
    },
    async emailSent() {

      this.resetEmail()
      await this.getMessages()
      await this.getUnreadCount()
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  truncate(message, noOfChar){
    return truncate(sender, noOfChar)
  }
}
</script>

<style lang="scss" scoped>
article.message {
  width: 80%;
  // &.message-reply {
  //   justify-content: end;
  //   > .message-body {
  //     text-align: right;
  //     // border-width: 0 6px 0 0;
  //   }
  // }
}
.message-body {
  width: 100%;
}
.is-info > .message-body,
.is-success > .message-body {
  border-width: 1px 1px 1px 8px;
}
.message-body.is-info {
  background: #e5f2ff;
  border-width: 1px 1px 1px 8px;
  // color: #0d5aa5;
  border-color: #5ba7f2;
}
.message-body.is-success {
  background: #f4ffeb;
  border-width: 1px 1px 1px 8px;
  // color: #52b300;
  border-color: #54b700;
}
.message-body.is-warning {
  background: #fff9eb;
  border-width: 1px 1px 1px 8px;
  // color: #0d5aa5;
  border-color: #ffb70f;
}
.message-sent {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
article.message {
  background-color: #fff;
}
.container {
  max-height: calc(100vh - 18em);
  overflow-y: auto;
  scroll-behavior: smooth;
}
@keyframes highlight-unread {
  0% {
    filter: brightness(0.4);
    filter: contrast(0.4);
    -webkit-filter: brightness(0.4);
    -webkit-filter: contrast(0.4);
  }
  50% {
    filter: brightness(0.6);
    filter: contrast(0.6);
    -webkit-filter: brightness(0.6);
    -webkit-filter: contrast(0.6);
  }
  100% {
    filter: brightness(1);
    filter: contrast(1);
    -webkit-filter: brightness(1);
    -webkit-filter: contrast(1);
  }
}
.unread-animation {
  animation-name: highlight-unread;
  animation-duration: 10s;
  animation-iteration-count: 1;
  font-weight: bold;
}
i.icon-green {
  color: #2bb741;
}
span.createdDate {
  margin-top: auto;
}

.speech-right {
  position: relative;
  margin-right: 5px;
}

// .speech-bubble::before {
//     content: "";
//     position: absolute;
//     display: block;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     //border: 1px solid #54b700;
//     // border-radius: 2px;
//     box-sizing: border-box;
//     /* clip-path: polygon(0% 105%, 0% 0%, 105% 0%, 105% 105%, 43px 105%, 43px 80%, 21px 80%, 21px 105%); */
// }

.speech-right::after {
  content: '';
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background: #54b700;
  top: 30%;
  right: -9px;
  box-sizing: border-box;
  clip-path: polygon(0 100%, 0 0, 100% 100%);
}

.speech-left {
  position: relative;
  margin-left: 5px;
}

.speech-left::after {
  content: '';
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background: #5ba7f2;
  top: 30%;
  left: -8px;
  box-sizing: border-box;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
</style>